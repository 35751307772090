@import-normalize;

body {
  margin: 0;
  background-color: #f6f7fb !important;
}

a {
  text-decoration: none;
  color: inherit;
}

@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'),
    url(./assets/fonts/ProximaNova-Regular.ttf) format('truetype');
}

.ant-message {
  z-index: 10000000 !important;
  /* we need to show toast as high as we can */
}
