.typographyMargin {
  margin-left: 262px !important;
  margin-top: 35px !important;
}

.typography {
  margin-left: 20px !important;
  margin-top: 35px !important;
}

.navbar {
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(188, 205, 220, 0.2) !important;
  max-width: 1492px;
  min-height: 0;
  width: auto;
  height: 52px;
}

.toolbar {
  min-height: 0 !important;
  display: inline-block !important;
}

.editButton {
  margin-left: 10px !important;
}

.backButton {
  margin-right: 10px !important;
}

.notifications {
  height: 20px !important;
  font-weight: 600;
  font-size: 14px;
}
